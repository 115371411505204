#contact .infos p,
#contact input[placeholder],
#contact textarea[placeholder] {
  font-size: 15px;
}

#contact .fas {
  color: #E28743;
  font-size: 26px;
}
#contact {
  margin-top: 20px;
}
form {
  width: 100%;
  height: 100%;
}

form input.form-control {
  padding-left: 40px;
}

form .input-icons i {
  position: absolute;
  font-size: 20px;
  margin-top: 8px;
  margin-left: 8px;
  opacity: .8;
}

form input .far {
  padding: 10px;
  min-width: 40px;
}

form .error {
  color: #ff5e00;
  font-size: 13px;
}

form button {
  border: 0;
  margin-bottom: 3px;
  display: block;
  width: 100%;
  height: 30px;
  font-size: 13px;
  line-height: 30px;
  background: #f8c132;
  color: rgb(0, 0, 0);
  text-transform: uppercase;
  margin-top: 1.5rem;
  cursor: pointer;
  cursor : arrow;
  transition: all .5s;
}

form button:hover {
  background: #f7ce66;
}
.map{
  width: 49%;
  height: "384px"
}
input,button{
  border-radius: 6px !important;
}
.shadow{
  transition: all 1.2s;
}
.shadow:hover{
  box-shadow: 0px 7px 9px -2px rgba(0,0,0,0.75) !important;
}

@media only screen and (max-width: 800px) {
  .map{
    width: 98%;   
    margin-left: 6px; 
  }
  .frmcart{
    padding-right: 0;
  }
}