.single__product-content {
  padding-left: 50px;
}
.price{
  color:#F89B34
}

.back:hover{
color:#F89B34;
cursor: pointer;
cursor : arrow;
}
.product__price {
  display: flex;
  align-items: center;
  column-gap: 1rem;
  margin-bottom: 0;
}
.product__price span {
  font-size: 2rem;
}

.category {
  font-weight: 600;
  color: #212245;
  display: flex;
  align-items: center;
  column-gap: 1rem;
  margin-top: 10px;
}

.category span {
  padding: 5px 10px;
  background: #fde4e4;
  color: #212245;
  border-radius: 5px;
}

.tabs {
  border-bottom: 1px solid #fde4e4;
}

.tabs h6 {
  cursor: pointer;
  cursor : arrow;
}

.tab__content {
  padding: 30px 0px;
}

.tab__content p {
  line-height: 30px;
  color: #212245b6;
}

.tab__active {
  color: #F89B34;
  border: 1px solid #F89B34;
  padding: 5px;
  margin: 0;
  border-bottom: 0;
}

.form {
  width: 60%;
  margin: auto;
  background: #f8f4f1;
  padding: 20px;
  border-radius: 5px;
  font-size: 1.2rem;
   
}

.form__group {
  margin-bottom: 30px;
}
.form__group input,
.form__group textarea select{
  width: 100%;
  background: transparent;
  padding: 7px 20px;
  border: none;
  border-bottom: 1px solid #2122454c;
}

.form__group input:focus,
.form__group textarea:focus,
.form__group select:focus
 {
  outline: none;
}

.user__name {
  font-weight: 600;
  color: #212245;
}

.user__email {
  font-size: 1.2rem;
}

.feedback__text {
  color: #212245b6;
}

.img__item {
  cursor: pointer;
  cursor : arrow;
}
.related__Product-title {
  font-size: 1.4rem;
}

@media only screen and (max-width: 768px) {
  .product__title {
    font-size: 1.2rem;
  }
  .product__price,
  .product__price span {
    font-size: 1rem;
  }
  .category,
  .category span {
    font-size: 0.8rem !important;
  }

  .tabs {
    margin-top: 30px;
  }
  .tabs h6 {
    font-size: 0.9rem;
  }

  .tab__content p,
  .user__name,
  .feedback__text {
    font-size: 0.8rem;
  }
  .user__email {
    font-size: 0.7rem;
  }

  .form {
    width: 100% !important;
    margin-top: 50px;
  }

  .form__group input::placeholder,
  .form__group textarea::placeholder,.form__group select::placeholder
   {
    font-size: 0.8rem;
  }
  .related__Product-title {
    font-size: 1.2rem;
  }
}

@media only screen and (max-width: 576px) {
  .product__images {
    display: flex;
    flex-direction: row;
  }

  .product__main-img {
    margin-bottom: 20px;
  }
  .fontResp{
    font-size: 24px !important;
  }
}



figure img {
  margin-top: 18px;
	transform: scale(0.95);
	transition: .3s ease-in-out;
}
figure:hover img {
	transform: scale(1.05);
}

.allviewlink{
  cursor:pointer !important;
  cursor : arrow
}
.allviewlink:hover{
  color:#E28743 !important;
  
}
.wish{
  cursor:pointer;
  transition: all 0.5s
}
.wish:hover{
  background-color: rgba(232, 37, 37, 0.103);
}
.heart1:hover{
  color:red !important
}

.share{
 color:#d17b18;
 transition:all 1s
}
.share:hover{
  color:#f7c081;
  transition:all 1s
 }
