.btnClose{
 border: 1px solid #F89B34;
 color : #F89B34;
} 
.btnCart{
    background-color: #F89B34;
    color: #fff;
} 

.cartAlert{
 color:#F89B34
}