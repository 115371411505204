

.cart__close {
  width: 100%;
  height: 60px;

  padding: 10px 20px;
}

.cart__close span i {
  font-size: 1.1rem;
  background-color: #212245;
  padding: 5px;
  border-radius: 50%;
  color: #fff;
  cursor: pointer;
  cursor : arrow;
}


.cart__bottom {
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 5px 20px;
  width: 100%;
  height: 80px;
  background: #E28743;
}

hr{
  background-color: "#434341";
  width:"100%";
}

.cart__bottom p span {
  color: #ffffff;
  margin-left: 10px;
}

.cart__bottom button {
  border: none;
  padding: 4px 20px;
  border-radius: 5px;
  background: #fff;
  color: #212245;
}

.cart__bottom button a {
  color: #212245;
  font-weight: 600;
}

@media only screen and (max-width: 800px) {
  .cart {
    width: 300px !important;
  }
  .cart__item-list {
    height: auto;
    overflow: auto
  }
}
