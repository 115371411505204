

.arrow-content-tooltip .react-tooltip-lite {
  box-sizing: border-box;
  border: 1px solid gray;
  border-radius: 8px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.2);
  width: 250px; 
}

.arrow-content-tooltip .react-tooltip-lite-down-arrow svg {
  transform: translateY(1px);
}

.arrow-content-tooltip .react-tooltip-lite-right-arrow svg {
  transform: rotate(270deg) translateY(-4px) translateX(-4px);
}
.arrow-content-tooltip .react-tooltip-lite-up-arrow svg {
  transform: rotate(180deg) translateY(1px);
}
.arrow-content-tooltip .react-tooltip-lite-left-arrow svg {
  transform: rotate(90deg) translateY(5px) translateX(4px);
}




.customer-reviews-item {
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 10px;
 
}
.customer-reviews-stars {
  /* margin-right: 15px; */
  /*  */
  width: 25%;
}
 .customer-reviews-slide {
  position: relative;
  width: 50%;
  /* width: 150px; */
  background: #e9e9e9;
  height: 3px;
  margin-right: 15px;
}
.customer-reviews-score {
  position: absolute;
  width: 60%;
  /* width: 50%; */
  background: #E28743;
  height: 3px;
}
.customer-reviews-per {
  /* width: 50px; */
  width: 18%;
  height: 20px;
  border-radius: 3px;
  border: 1px solid #ccc;
  text-align: center;

}
span.allviewlink{
  cursor: pointer !important;
  cursor: arrow; 
}
