header {
  position: fixed;
  top: 0;
  z-index: 999;
  transition: top 0.3s;
  width: 100%;
  background-color: var(--dark-blue);
  color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  padding: 2rem;
  height: 5rem;
  transition: 1s;
  box-shadow: 0px 6px 14px 0px rgba(0, 0, 0, 0.75);
}

.logo a h3 {
  width: 25%;
  color: #fff;
  cursor: pointer;
  cursor: arrow;
  span {
    color: #f89b34;
  }
}

nav {
  width: 75%;
  display: flex;
  justify-content: space-between;

  ul {
    display: flex;
    justify-content: space-between;
    align-items: center;
    list-style: none;
    .logo-mobile {
      display: none;
    }

    li {
      margin: 0 5px;
      a {
        color: #fff;
        &:hover {
          color: #f89b34;
        }
      }
    }
  }
}

.header-right {
  display: flex;
  align-items: center;

  span {
    margin: 0 5px;

    p {
      color: #fff;
    }
  }

  .links a {
    margin: 0 5px;
    color: #fff;
    &:hover {
      color: #f89b34;
    }
    &.active {
      color: var(--color-danger);
    }
  }
}

.menu-icon {
  cursor: pointer;
  cursor: arrow;
  display: none;
}
@media screen and (min-width: 800px) {
  .active {
    position: relative;
    color: var(--color-danger);
  }

  .active::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: -3px;
    width: 100%;
    height: 2px;
    background-color: #fff;
  }
}

.cartHidden,
.wish {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 1.4rem;
  margin-right: 4px;
}
.cartHidden:hover,
.wish:hover,
.account:hover {
  color: #f2b519 !important;
  cursor: pointer;
}
.account {
  color: #fff;
}
@media screen and (max-width: 800px) {
  .wish {
    font-size: 1.6rem !important;
    margin-right: 10px;
    i {
      margin-top: 2px;
    }
  }
  .cartHidden {
    display: none;
  }
  nav {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 50%;
    height: 100vh;
    background-color: var(--dark-blue);
    z-index: 999;
    transform: translateX(-200%);
    transition: all 0.3s;

    .nav-wrapper {
      position: absolute;
      top: 0;
      right: 0;
      width: 100%;
      height: 100vh;
      background-color: rgba(0, 0, 0, 0.5);
      transform: translateX();
      transition: all 0.3s;
    }

    .show-nav-wrapper {
      transform: translateX(100%);
    }

    ul {
      display: block;
      .logo-mobile {
        display: flex;
        justify-content: space-between;
        align-items: center;
        > * {
          cursor: pointer;
          cursor: arrow;
        }
      }
      li {
        padding: 10px 0;
        border-bottom: 1px solid #333;
        a {
          display: block;
        }
      }
    }

    .header-right {
      display: block;
      margin-left: 26px;
      .spanaccount {
        display: block !important;
        margin: 0 !important;
        padding: 0 !important;
        padding: 10px 0 !important;
        border-bottom: 1px solid #333 !important;
      }
      .links {
        display: block !important;
        margin: 0 !important;
        padding: 0 !important;
        a {
          display: block !important;
          margin: 0 !important;
          padding: 10px 0 !important;
          border-bottom: 1px solid #333 !important;
        }
      }
    }
  }

  .show-nav {
    transform: translateX(0);
  }
  .hide-nav {
    transform: translateX(-200%);
  }
  .menu-icon {
    display: flex;
    justify-content: flex-end;
    width: 300px;
    align-items: center;

    > * {
      margin-left: 2rem;
      display: flex;
      align-items: center;
    }
  }
  .cartbask {
    font-size: 16px;
    display: contents;
  }

  #dropdown {
    margin-top: 10px !important;
    border: 1px solid red !important;
  }
}
.show{
  transition: all 1s ease !important;
}