img.imgcheckout {
  width: 120px;
  height: 120px;
  object-fit: cover;
}

.increase__decrease-btn {
  background: #FFC107;
  padding: 2px 5px;
  border-radius: 5px;
  width: 80px;
  font-size: 1.3rem;
}

.increase__btn,
.decrease__btn,.delete__btn {
  cursor: pointer;
  cursor : arrow;
}

.delete__btn {
  font-size: 1.8rem;
  font-weight: 600;
}

.cart__item {
  margin-bottom: 15px;
  border-bottom: 1px solid #fde4e4;
}

@media only screen and (max-width: 576px) {
  .delete__btn {
    font-size: 1rem;
  }
}
