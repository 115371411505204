.cart__item-info img {
  width: 70px;
  height: 70px;
  object-fit: cover;
}

.increase__decrease-btn {
  background: #FFC107;
  padding: 2px 5px;
  border-radius: 5px;
  width: 80px;
  font-size: 1.1rem;
}

.increase__btn,
.decrease__btn,.delete__btn {
  cursor: pointer;
  cursor : arrow;
}

.delete_btn {
  font-size: 16px;
  font-weight: 600;
  cursor: pointer; 
  transition: all 0.2s;
}
.delete_btn:hover{
  font-size: 18px;
}
.cart__item {
  margin-bottom: 15px;
  border-bottom: 1px solid #fde4e4;
}

@media only screen and (max-width: 576px) {
  .delete_btn {
    font-size: 14px;
  }
}

.quantprice{
  font-weight: 500;
}