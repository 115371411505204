.card {
  border-radius: 7px;

  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.08), 0 0 6px rgba(0, 0, 0, 0.05);
  transition: 0.3s transform cubic-bezier(0.155, 1.105, 0.295, 1.12),
    0.3s box-shadow,
    0.3s -webkit-transform cubic-bezier(0.155, 1.105, 0.295, 1.12);
  padding: 14px 80px 18px 36px;
  cursor: pointer;     
}

.card:hover {
  opacity: 0.8;
  transform: scale(1.05) !important;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.12), 0 4px 8px rgba(0, 0, 0, 0.06);
}

.ms-n5 {
  margin-left: -30px;
}

.img-card {
  width: 100px !important;
  height: 100px !important;
}

.imgView {
  width: 37px !important;
  height: 23px !important;
}

@media screen and (max-width: 800px) {


  .hidden-view {
    display: none !important;
  }
  .contentHome {
    margin-left: 0;
    margin-right: 0;
  }
}

.form {
  margin: 0 auto;
  padding: 100px 30px 0;

  @media (min-width: 800px) {
    max-width: 60%;
  }
}

.input-range {
  margin-bottom: 160px;
}

.detail{
  padding-top:9rem
}

