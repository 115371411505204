 
  .ms-n5 {
    margin-left: -30px;
  }
  
  .img-card {
    width: 100px !important;
    height: 100px !important;
  }
  
  .imgView {
    width: 37px !important;
    height: 23px !important;
  }
  
  @media screen and (max-width: 800px) {
  
  
    .hidden-view {
      display: none !important;
    }
    .contentHome {
      margin-left: 0;
      margin-right: 0;
    }
  }
  
  .form {
    margin: 0 auto;
    padding: 100px 30px 0;
  
    @media (min-width: 800px) {
      max-width: 60%;
    }
  }
  
  .input-range {
    margin-bottom: 160px;
  }
  
  .detail{
    padding-top:9rem
  }
  