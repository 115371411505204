.txtorange,
.font:hover,
.txtmiddle,
.nowtitre {
  color: #f89b34 !important;
}
.hrStyle {
  background-color: #f89b34;
  width: 100%;
  height: 2px !important;
  margin-top: 0;
}
.font {
  font-size: 16px;
  color:#0A1930;
}
.font:hover {
  color: #f89b34 !important;
}
.rowVision {
  height: 310px;
}
.ourVision1 {
  background-image: url("../../assets/shoping.webp");
  height: 310px;
  width: 100%;
  opacity: 0.7;
}
.ourVision2 {
  /* background-color: rgb(226, 135, 67, 0.9); */
  background-color: rgba(190, 106, 11, 0.7);
  height: 310px;
  width: 100%;
}
.ourVision3 {
  border-radius: 10px;
  border: 1.5px solid #fce2bd;
  width: 60%;
  height: 50%;
  margin: auto !important;
}
.glasses {
  color: #ffffff;
}
.now {
  background-color: #f8f4f1;
}

.txtimgNow1 {
  position: relative;
  top: -75px;
}
.txtimgNow2 {
  position: relative;
  top: -45px;
}
.colvision div p {
  padding: 0;
  margin: 0;
  font-weight: 400;
}
.rownow {
  margin-top: 80px;
}

.nowimg {
  width: 150px;
  height: 150px;
  padding: 0;
  text-align: center;
}

.nowCol p{
  padding: 15px;
  font-weight: 500;
  height: 100px;
}
.nowimg1 {
  background: url(../../assets/nowimg1.png) no-repeat 0px 0px;
  background-size: contain;
}
.nowimg2 {
  background: url(../../assets/nowimg2.png) no-repeat 0px 0px;
  background-size: contain;
}
.nowimg3 {
  background: url(../../assets/nowimg3.png) no-repeat 0px 0px;
  background-size: contain;
}
.nowimg4 {
  background: url(../../assets/nowimg4.png) no-repeat 0px 0px;
  background-size: contain;
}
.nowimg5 {
  background: url(../../assets/nowimg5.png) no-repeat 0px 0px;
  background-size: contain;
}
.nowimg6 {
  background: url(../../assets/nowimg6.png) no-repeat 0px 0px;
  background-size: contain;
}
.nowimg7 {
  background: url(../../assets/nowimg7.png) no-repeat 0px 0px;
  background-size: contain;
}
.nowimg8 {
  background: url(../../assets/nowimg8.png) no-repeat 0px 0px;
  background-size: contain;
}

.now ul li{
  text-align: justify;
  padding: 10px;
}
.hovenow{
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.2);
transition: all .5s;
}
.hovenow:hover{
  box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.2);

}
.about{
  margin-top: 80px;
}
.visionmenu{
  background-color: #f8f4f1;
  padding-top: 12px;
  padding-bottom: 12px;
width: 40rem;
display:inline-block;
  border-radius: 50px;
  margin: auto;  
  box-shadow: 0px 3px 7px 1px rgb(0 0 0 / 50%);
}
.font1{
  margin-right: 15px;
}
.font2{
  margin-right: 15px;
  margin-left: 15px;
}
.font3{
  margin-left: 15px;
}

@media screen and (max-width: 800px) {
.font1{
  display: none;
}
.font2{
  margin-right: 15px;
  margin-left: 15px;
}
.font3{
  margin-left: 15px;
}
.history{
  display: block;
}
}
