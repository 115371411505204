.btn-circle {
    width: 24px;
    height: 24px;
    padding: 6px 0px;
    border-radius: 15px;
    text-align: center;
    font-size: 12px;
    line-height: 1.42857;
    background-repeat: no-repeat;
}

