.cart__container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(216, 198, 139, 0.219);
  z-index: 99999;
}

.cart {
  position: absolute;
  top: 1.5%;
  right: 1%;
  width: 33%;
  height: 97%;
  background: #fff;
  z-index: 999999;
  border-radius: 2%;
  border: 2px solid#E99734;

}

.cart_close {
  width: 100%;
transition: all 0.5s fade;
  padding: 10px 20px;
}
.cart_close span i:hover{
   background-color: #f4bc77;
}
.cart_close span i {
  font-size: 1.6rem;
  background-color: #E99734;
  padding: 5px;
  border-radius: 50%;
  color: #7f4e12;
  cursor: pointer;
  cursor : arrow;
 transition: all 0.5s ;
 font-weight: 600;
}

.cart_item-list_right {
  height: calc(100vh - 210px);  
  overflow: auto;
}

.cart_bottom {
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 5px 20px;
  width: 100%;
  height: 60px !important;
  background: #E99734;
}

hr{
  background-color: #434341;
  width:"100%";
}

.cart_bottom p span {
  color: #ffffff;
  margin-left: 10px;
}

.cart_bottom button {
  border: none;
  padding: 4px 20px;
  border-radius: 5px;
  background: #fff;
  color: #212245;
  transition: all 0.3s;
}
.cart_bottom button:hover{ 
  background: rgb(245, 239, 239);
}

.cart_bottom button a {
  color: #212245;
  font-weight: 600;
}



.noitem span,.noitem p{
  color:#282828;
  font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
 
  line-height: 21ps;
}
.noitem span{
  font-size: 20px;
  font-weight: 600;
}
.noitem p{
  font-size: 18px;
  font-weight: 400;
}
.noitem button{
  border-radius: 3px !important;
  padding:  8px 24px !important;
}



.cart-icons-list {
display: flex;
justify-content: center;
  font-size: 14px;
  font-weight: 600;
  color: #222;
 
}
.cart-icons-list span{
  margin-top: 2px;
  margin-left: 14px;
  margin-right: 0px;
}
.cart-icons-img {
  height: 24px;
  width: 28px;
  margin: 0px 5px;
}

@media only screen and (max-width: 800px) {


}
@media only screen and (max-width: 700px) {
  .cart {
    width: 70% !important;
  }
  .cart-icons-list{    
    margin-left: 10px;
  }
  .cart-icons-list div{  
    width: 40%;
  }
  .cart_item-list_right {
    padding-bottom: 40px;
  }
}

/* width */
.cart_item-list_right::-webkit-scrollbar {
  width: 10px;
}

/* Track */
.cart_item-list_right::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
 
/* Handle */
.cart_item-list_right::-webkit-scrollbar-thumb {
  background: #fce297; 
}

/* Handle on hover */
.cart_item-list_right::-webkit-scrollbar-thumb:hover {
  background: rgb(250, 221, 134); 
}