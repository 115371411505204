.FormGroup {
  margin: 20px 0 0px;
  padding: 0;
  border-style: none;
  background-color: #ffffff;
  will-change: opacity, transform;
  box-shadow: 0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08),
    inset 0 1px 0 #f89b34;
  border-radius: 4px;
}

.FormRow {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  margin-left: 15px;
  border-top: 1px solid #ffffff;
}

.StripeElement--webkit-autofill {
  background: transparent !important;
}

.StripeElement {
  width: 100%;
  padding: 11px 15px 11px 0;
}

.button {
  display: block;
  font-size: 16px;
  width: calc(100% - 30px);
  height: 40px;
  margin: 40px 15px 0;
  background-color: #f6a4eb;
  box-shadow: 0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08),
    inset 0 1px 0 #ffb9f6;
  border-radius: 4px;
  color: #fff;
  font-weight: 600;
  cursor: pointer;
  transition: all 100ms ease-in-out;
  will-change: transform, background-color, box-shadow;
  border: none;
}

.button:active {
  background-color: #d782d9;
  box-shadow: 0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08),
    inset 0 1px 0 #e298d8;
  transform: scale(0.99);
}


.change{
  color:#f89b34 !important;
}
.change:hover{
  color: #ffc88a !important;
}
.frm {
  margin-top: 100px;
  margin-bottom: 100px;
}

.checkout__bill {
  background: #ffffff;
  padding: 12px;
  color: #333333;
  font-size: 24px !important;
  margin-bottom: 10px;
  height: 95px;
  border: 1px solid #cbcaca;
  border-radius: 5px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.3);

}
.checkout__bill h5 {
  font-size: 16px !important;
}
.checkout__total {
  border-top: 1px solid #cbcaca;
  margin-bottom: 10px;
}
.checkout__total h4 ,.at_order{
  color: #f89b34 !important;
}

/* a verifier */
.fontfrm {
  font-family: Poppins, sans-serif !important;
  font-weight: 400 !important;
  font-size: 16px !important;
  font-style: normal !important;
  line-height: 21px !important;
  padding: 7px 20px !important;
  color: #757575;
  border: none;
  border-bottom: 1px solid #2122454c;  border-radius: 5px;

}
.fontfrm:focus {
  outline: none;
}
.fontfrm::placeholder {
  font-size: 16px !important;
}

.cart-icons-list {
  display: flex;
  justify-content: center;
  font-size: 14px;
  font-weight: 600;
  color: #222;
}
.cart-icons-list span {
  margin-top: 2px;
}
.cart-icons-img {
  height: 24px;
}

.products {
  height: calc(100vh -60px) !important;
  /* height: 100vh !important; */
}

.safe-info-img {
  height: 22px;
}
.safe-info-text {
  margin: 0;
  font-size: 14px;
  color: #666;
}
.undersummary{
  border: 1px solid #ccc;
  padding: 20px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.3);
  border-radius: 3px;
  margin-top: 20px;
  margin-bottom: 19px;

}
.logo a h3 {
  width: 20%;
  color: #666;
  cursor: pointer;
  cursor: arrow;
}

.logo a h3 span {
  color: #f89b34;
}
#container-element{
	position: relative;
}
.fixed {
  /* position: fixed;
	right:12%;
	width: 32%;	 */
    position: -webkit-sticky;
    position: sticky;
    top: 80px;
    height: -webkit-max-content;
    height: -moz-max-content;
    height: max-content;

}

.Payemnt{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
@media only screen and (min-width: 768px) {
  #container-element{
    display: flex;
    justify-content: space-between;
  }
}
@media only screen and (max-width: 768px) {


  .checkout__bill {
    margin-top: 40px;
  }
  .products {
    margin-top: 10px;
  }
  .Payemnt{
    display: contents;
  }
}
